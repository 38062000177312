import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";

import { connect } from 'react-redux';
import * as logoutActions from '../../actions/auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

import axios from "axios";

// import avatar from "../../assets/images/avatar.jpg";

import {NavLink,  useHistory} from "react-router-dom";


const HeaderUserbox = ({logout, userInfo, logoInfo, firstName, lastName, avatar, email}) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [multipleCompanies, setMultipleCompanies] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const id = localStorage.getItem('evolve_userId') || sessionStorage.getItem('evolve_userId');
  const token = localStorage.getItem('evolve_token') || sessionStorage.getItem('evolve_token');

  const history = useHistory();

  const switchCompany = () => {
    const  port =  window.location.port;
    let hostName = window.location.hostname.replace(/^[^.]+\./g, "");
    if ( window.location.hostname.includes(".localhost")) {
      window.location = `http://${hostName}:${port}/sign-in-company?email=${email}`
    }
    if (window.location.hostname.includes(".evolvemymeetings")) {
      window.location = `https://${hostName}/sign-in-company?email=${email}`
    }
  };

  useEffect(() => {
    const rootUrl = axios.defaults.baseURL;
    const url = rootUrl + 'users/' + id;
    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(response => {
        userInfo(response.data.role, response.data.first_name, response.data.last_name, response.data.company.id, response.data.avatar, response.data.company.slug, response.data.email);
        logoInfo(response.data.company.logo);
        setMultipleCompanies(response.data.multiple_companies);
      })
      .catch(err => {
        console.log('ERROR', err);
        if (err.response.data.error === "You need to sign in or sign up before continuing.") {
          localStorage.removeItem('evolve_token');
          localStorage.removeItem('evolve_userId');
          sessionStorage.removeItem('evolve_token');
          sessionStorage.removeItem('evolve_userId');
          history.replace('/sign-in');
          window.location.reload();
        }
      });
  }, [id, userInfo, token, history, logoInfo]);

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="position-relative ml-2">
        <DropdownToggle
          color="link"
          className="p-0 text-left d-flex btn-transition-none align-items-center">
          <div className="d-block p-0 avatar-icon-wrapper">
            <div className="avatar-icon rounded font-weight-bold">
              {avatar ? <img src={avatar} alt="avatar" /> : null}
              {firstName === null || lastName === null ? null : firstName.charAt(0).toUpperCase() +  lastName.charAt(0).toUpperCase()}
            </div>
          </div>
          <div className="d-none d-xl-block pl-2">
            <div className="font-weight-bold">{firstName === null || lastName === null ? null : firstName + " " + lastName}</div>
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon
              icon={['fas', 'angle-down']}
              className="opacity-5"
            />
          </span>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
          <ListGroup flush className="text-left bg-transparent">
            <ListGroupItem className="rounded-top">
              <Nav pills className="nav-neutral-primary flex-column">
                <NavItem>
                  <NavLink
                    to={"/users/" + id}
                    className="nav-link-simple"
                    onClick={toggle}
                  >
                    My profile
                  </NavLink>
                </NavItem>
                {
                  multipleCompanies ?
                    <li className="nav-item">
                      <span
                        className="nav-link-simple"
                        onClick={switchCompany}
                      >Switch company</span>
                    </li> :
                    null
                }

                <NavItem>
                  <NavLink
                    to="/sign-in-email"
                    onClick={logout}
                    className="nav-link-simple"
                    >
                     Sign out
                  </NavLink>
                </NavItem>
              </Nav>
            </ListGroupItem>
          </ListGroup>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userId: state.Auth.userId,
    token: state.Auth.token,
    firstName: state.Auth.first_name,
    lastName: state.Auth.last_name,
    avatar: state.Auth.avatar,
    email: state.Auth.email,
  }
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(logoutActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);


