import React, { lazy, Suspense, useEffect } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";

import * as authActions from './actions/auth';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ScaleLoader } from 'react-spinners';

// Layout Blueprints

import {
  LeftSidebar,
  MinimalLayout,
} from './layout-type';


const Profile= lazy(() =>
  import('./pages/PageUser')
);
const  AboutCompany= lazy(() =>
  import('./pages/PageAboutCompany')
);
const  Departments= lazy(() =>
  import('./pages/PageDepartments')
);
const  Offices= lazy(() =>
  import('./pages/PageOffices')
);
const  Teams= lazy(() =>
  import('./pages/PageTeams')
);
const  Positions= lazy(() =>
  import('./pages/PagePositions')
);
const Statistics= lazy(() =>
  import('./pages/PageStatistics')
);
const Meetings= lazy(() =>
  import('./pages/PageMeetings')
);

const EditMeeting= lazy(() =>
  import('./pages/PageEditMeeting')
);

const CreateMeeting= lazy(() =>
  import('./pages/PageCreateMeeting')
);

const Users= lazy(() =>
  import('./pages/PageUsersList')
);

const SignUpStep1 = lazy(() =>
  import('./components/Authorization/SignUpStep1')
);

const SignUpStep2 = lazy(() =>
  import('./pages/PageSignUpStep2')
);
const SignInWithEmail = lazy(() =>
  import('./components/Authorization/SignInWithEmail')
);

const SignInWithCompany = lazy(() =>
  import('./components/Authorization/SignInWithCompany')
);

const SignUpSimple = lazy(() =>
  import('./pages/PageSignUpSimple')
);
const SignIn = lazy(() =>
  import('./pages/PageSignIn')
);
const RecoverPassword = lazy(() =>
  import('./pages/PageRecoverPassword')
);
const NewPassword = lazy(() =>
  import('./pages/PageNewPassword')
);
const PageError404 = lazy(() =>
  import('./pages/PageError404')
);



const Routes = ({authCheckState, isUserRole, userIdState, userRole}) => {

  useEffect(() => {
    authCheckState();
  }, [authCheckState]);

  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ScaleLoader color={'#BF1E2E'} loading={true} />
             </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the page
          </div>
        </div>
      </>
    );
  };

  let check =  sessionStorage.getItem('evolve_token') || localStorage.getItem('evolve_token');
  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>

          {!check ?  <Redirect exact from="/" to="/sign-in-email" /> :
           <Redirect exact from="/" to="/feedback" />}

          {
            check ? <Route
              path={[
                '/feedback',
                '/users',
                '/meetings',
                '/create-meeting',
                '/about-company',
                '/departments',
                '/offices',
                '/teams',
                '/positions',
                '/sign-up',
                '/sign-up-2',
                '/sign-up-simple',
                '/sign-in',
                '/sign-in-email',
                '/sign-in-company',
                '/recover-password',
                '/new-password',
                '/uploads'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <Redirect exact from="/sign-up" to="/feedback" />
                  <Redirect exact from="/sign-up-2" to="/feedback" />
                  <Redirect exact from="/sign-up-simple" to="/feedback" />
                  <Redirect exact from="/sign-in" to="/feedback" />
                  <Redirect exact from="/sign-in-email" to="/feedback" />
                  <Redirect exact from="/sign-in-company" to="/feedback" />
                  <Redirect exact from="/recover-password" to="/feedback" />
                  <Redirect exact from="/new-password" to="/feedback" />

                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      exact
                      path="/feedback"
                      component={Statistics}
                    />
                    <Route path="/users/:id" render={({match}) => {
                      let res;
                      !isUserRole && +userIdState !== +match.params.id ? res = null : res = <Profile userId={match.params.id} />;
                      return res;
                    }} />
                    {
                      userRole === "master_admin" ?
                        <>
                          <Route
                            path="/about-company"
                            component={AboutCompany}
                          />
                          <Route
                            path="/departments"
                            component={Departments}
                          />
                          <Route
                            path="/offices"
                            component={Offices}
                          />
                          <Route
                            path="/teams"
                            component={Teams}
                          />
                          <Route
                            path="/positions"
                            component={Positions}
                          />
                        </>
                        : null
                    }
                    {
                      isUserRole ? <Route
                        path="/users"
                        exact
                        component={Users}
                      /> : null
                    }
                    <Route
                      path="/meetings"
                      exact
                      component={Meetings}
                    />
                    <Route path="/meetings/:id" render={({match}) => {
                      return <EditMeeting meetingId={match.params.id} />;
                    }} />
                    <Route
                      path="/create-meeting"
                      component={CreateMeeting}
                    />

                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route> :
              <Route
                path={[
                  '/feedback',
                  '/users',
                  '/meetings',
                  '/create-meeting',
                  '/about-company',
                  '/departments',
                  '/offices',
                  '/teams',
                  '/positions',
                  '/sign-up',
                  '/sign-up-2',
                  '/sign-up-simple',
                  '/sign-in',
                  '/sign-in-email',
                  '/sign-in-company',
                  '/recover-password',
                  '/new-password',
                ]}>

                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <Redirect exact from="/feedback" to="/sign-in-email" />
                    <Redirect from="/users" to="/sign-in-email" />
                    <Redirect from="/meetings" to="/sign-in-email" />
                    <Redirect exact from="/create-meeting" to="/sign-in-email" />
                    <Redirect exact from="/about-company" to="/sign-in-email" />
                    <Redirect exact from="/departments" to="/sign-in-email" />
                    <Redirect exact from="/offices" to="/sign-in-email" />
                    <Redirect exact from="/teams" to="/sign-i-email" />
                    <Redirect exact from="/positions" to="/sign-in-email" />

                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route
                        path="/sign-up"
                        component={SignUpStep1}
                      />
                      <Route
                        path="/sign-up-2"
                        component={SignUpStep2}
                      />
                      <Route
                        path="/sign-up-simple"
                        component={SignUpSimple}
                      />
                      <Route
                        path="/sign-in"
                        component={SignIn}
                      />
                      <Route
                        path="/sign-in-email"
                        component={SignInWithEmail}
                      />
                      <Route
                        path="/sign-in-company"
                        component={SignInWithCompany}
                      />
                      <Route
                        path="/recover-password"
                        component={RecoverPassword}
                      />
                      <Route
                        path="/new-password"
                        component={NewPassword}
                      />

                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>
          }
          <Route
            component={PageError404}
          />

        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.Auth.token !== null,
    isUserRole: state.Auth.role === "master_admin" || state.Auth.role === "admin",
    userRole: state.Auth.role,
    userIdState: state.Auth.userId,
  }
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
