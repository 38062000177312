import {updateObject} from "../utils/updateObject";

const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  role: null,
  first_name: null,
  last_name: null,
  companyId: null,
  avatar: null,
  logo: null,
  slug: null,
  email: null
};

const authStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    // error: null,
    // loading: false
  });
};

const userInfo = (state, action) => {
  return updateObject(state, {
    role: action.role,
    first_name: action.first_name,
    last_name: action.last_name,
    companyId: action.companyId,
    avatar: action.avatar,
    slug: action.slug,
    email: action.email
  });
};

 const logoInfo = (state, action) => {
  return updateObject(state, {
    logo: action.logo,
    // companyId: action.companyId,
    loading: true
  });
};


const authFail = (state, action) => {
  return updateObject(state, {
    error: action.payload,
    // loading: false
  })
};

const clearFail = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    token: null,
    userId: null
  });
};

export default (state = initialState, action) => {
  switch  (action.type) {
    case 'AUTH_START': return authStart(state, action);
    case 'AUTH_SUCCESS': return authSuccess(state, action);
    case 'SET_FAIL': return authFail(state, action);
    case 'AUTH_LOGOUT': return authLogout(state, action);
    case 'CLEAR_FAIL': return clearFail(state, action);
    case 'USER_INFO': return userInfo(state, action);
    case 'LOGO_INFO': return logoInfo(state, action);
    default:
      return  state;
  }
}
