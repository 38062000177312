import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';

const token = localStorage.getItem('evolve_token') || sessionStorage.getItem('evolve_token');

axios.defaults.baseURL = 'https://evolvemymeetings.com/api/v1/';
// axios.defaults.baseURL = 'https://2d337c9368b5.ngrok.io/api/v1/';
axios.defaults.headers.common['Authorization'] = 'Bearer '.concat(token);
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'content-type';
axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'POST';

ReactDOM.render(<App />, document.getElementById('root'));



