import axios from 'axios';

export const authStart = () => ({
  type: 'AUTH_START'
});


export const authSuccess = (token, userId, role) => ({
  type: 'AUTH_SUCCESS',
  idToken: token,
  userId: userId
});

export const authFail = (error) => ({
  type: 'SET_FAIL',
  payload: error
});

export const userInfo = (role, first_name, last_name, companyId, avatar, slug, email) => ({
  type: 'USER_INFO',
  role: role,
  first_name: first_name,
  last_name: last_name,
  companyId: companyId,
  avatar: avatar,
  slug: slug,
  email: email
});

export const logoInfo = (logo, companyId) => ({
  type: 'LOGO_INFO',
  logo: logo,
  // companyId: companyId
});



export const logout = () => {
  let rootUrl = axios.defaults.baseURL;
  let url = rootUrl + 'users/sign_out';
  axios.post(url)
    .then(() => {

    })
    .catch(err => {
      console.log('error', err.response.data.errors);
    });

  localStorage.removeItem('evolve_token');
  localStorage.removeItem('evolve_userId');
  sessionStorage.removeItem('evolve_token');
  sessionStorage.removeItem('evolve_userId');
  return {
    type: 'AUTH_LOGOUT',
    idToken: null,
    userId: null,
    first_name: null,
    last_name: null,
    avatar: null,
    logo: null
  };
};

export const authClearFail = () => {
  return {
    type: 'CLEAR_FAIL'
  };
};

export const auth = (
  firstName,
  lastName,
  email,
  password,
  isSignUp,
  isRemember,
  isSignUpSimple,
  invitedRegistrationToken,
  companyId,
  companyToken,
  slug
) => {
  return dispatch => {

    dispatch(authStart());

    let authData = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        agreement: true,
        // company_id: companyId,

      },
      token: companyToken
    };

    let rootUrl = axios.defaults.baseURL;
    let url = rootUrl + 'users/sign_up';


    if (!isSignUp) {
      url = rootUrl + 'users/sign_in';
      authData = {
        email: email,
        password: password,
        slug: slug
      };
    }

    if (isSignUpSimple) {
      url = rootUrl + 'users/invited_registration';
      authData = {
        user: {
          email: email,
          password: password,
          agreement: true,
          token: invitedRegistrationToken,
          company_token: companyToken
        }
      };
    }

    axios.post(url, authData)
      .then(response => {
        window.location = '/feedback';
        if (isSignUp) {
          localStorage.setItem('evolve_token', response.data.user.authentication_token);
          localStorage.setItem('evolve_userId', response.data.user.id);
          // dispatch(authSuccess(response.data.user.authentication_token, response.data.user.id));
        } else {
          if (isRemember) {
            localStorage.setItem('evolve_token', response.data.user.authentication_token);
            localStorage.setItem('evolve_userId', response.data.user.id);
          } else {
            sessionStorage.setItem('evolve_token', response.data.user.authentication_token);
            sessionStorage.setItem('evolve_userId', response.data.user.id);
          }

        }
        dispatch(authSuccess(response.data.user.authentication_token, response.data.user.id));
      })
      .catch(err => {
        console.log('error', err.response.data.errors);
        dispatch(authFail(err.response.data.errors));
      });


  }
};

export const authCheckState = () => {
  return dispatch => {
    let localToken = localStorage.getItem('evolve_token');
    let sessionToken = sessionStorage.getItem('evolve_token');
    if (localToken) {
      let localUserId = localStorage.getItem('evolve_userId');
      dispatch(authSuccess(localToken, localUserId));
    }

    if (sessionToken) {
      let sessionUserId = sessionStorage.getItem('evolve_userId');
      dispatch(authSuccess(sessionToken, sessionUserId));
    }
  };
};
